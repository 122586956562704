import moment from 'moment'

export const DEFAULT_FORM_VALUES = {
  fullName: '',
  emailAddress: '',
  phone: ''
}

export function data() {
  return {
    formComplete: false,
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    toggle: false,
    toggleVehicle: false,
    loading: true,
    isDebug: true,
    profile: null,
    primaryOwner: null,
    unitDetail: null,
    hoaList: [{}],
    formatDateFn: function(value) {
      return value && value != undefined
        ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
            .format('MM-DD-YYYY')
            .toString()
        : ''
    },
    rows: [],
    selectedRow: null,
    vehicleList: [{}],
    ownerID: 0,
    transitionName: 'slide-down',
    tabs: [
      { name: 'contacts', label: 'Contacts' },
      {
        label: 'Homeowner Directory Sharing',
        name: 'directoryContactAdmin'
      },
      { name: 'mailingAddress', label: 'Mailing Address' },
      { name: 'mailDelivery', label: 'Mail Delivery' },
      { name: 'vehicles', label: 'Vehicles' }
    ]
  }
}
