<template>
  <PageContent>
    <div class="box is-12" tests-id="propertySettings-page">
      <div class="tile is-ancestor is-12">
        <div class="tile is-vertical is-12">
          <div class="level">
            <div class="p-5 level-left level-item title">Property Settings</div>
            <div class="pr-5 level-right">
              <div class="level-item" v-if="unitDetail && unitDetail != undefined">
                <label class="pr-2 h6 is-inline">Property Id:</label>
                {{ `${unitDetail.lotNumber || ''}` }}
              </div>
            </div>
          </div>
          <div class="level" v-if="unitImageBase64">
            <div class="level-center level-item">
              <figure class="image is-8x4 details-img">
                <img :style="{ maxWidth: '800px' }" :src="unitImageBase64" />
              </figure>
            </div>
          </div>
          <div class="level">
            <div
              class="pl-5 title is-5 is-vcentered level-left level-item"
              v-if="
                unitDetail &&
                  unitDetail != undefined &&
                  unitDetail.address &&
                  unitDetail.address != undefined
              "
            >
              {{ `${unitDetail.address.addressOne || ''}` }}
            </div>
            <div class="level-right">
              <div class="pr-5 level-item" v-if="primaryOwner && primaryOwner != undefined">
                <label class="pr-2 h6 is-inline">Deeded Name:</label>
                {{ `${primaryOwner.label || ''}` }}
              </div>
            </div>
          </div>
          <div class="level">
            <div
              class="pl-5 subtitle is-6 is-vcentered level-left level-item"
              v-if="
                unitDetail &&
                  unitDetail != undefined &&
                  unitDetail.address &&
                  unitDetail.address != undefined
              "
            >
              {{
                `${unitDetail.address.city || ''}, ${unitDetail.address.state || ''}  ${unitDetail
                  .address.postalCode || ''}`
              }}
            </div>
            <div class="level-right">
              <div
                class="pr-5 level-item"
                v-if="
                  unitDetail &&
                    unitDetail != undefined &&
                    unitDetail.primaryOwner &&
                    unitDetail.primaryOwner != undefined
                "
              >
                <label class="pr-2 h6 is-inline">Settlement Date:</label>
                {{ `${formatDateFn(unitDetail.primaryOwner.settlementDate)}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      id="tabs"
      :style="{
        minHeight: '800px'
      }"
    >
      <div class="card-content">
        <LinksMenu
          aria-label="account settings tabs"
          :tabs="tabs"
          prefix="property."
          :style="{
            paddingLeft: '1px',
            overflowX: 'auto',
            width: '100%'
          }"
          :styles="{ ul: { width: '150px' } }"
          :params="{
            id: `${
              unitDetail && unitDetail.primaryOwnerID && unitDetail.primaryOwnerID !== undefined
                ? unitDetail.primaryOwnerID
                : 0
            }`
          }"
        >
          <section :style="{ overflowX: 'auto', flexGrow: 10 }">
            <SlideTransition :name="transitionName">
              <keep-alive>
                {{ transitionName }}
                <router-view :key="$route.name" />
              </keep-alive>
            </SlideTransition>
          </section>
        </LinksMenu>
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import kms from '@/services/kms'
import { mapState, mapGetters, mapActions } from 'vuex'
import SlideTransition from '@/components/transitions/Slide.vue'
import LinksMenu from '@/components/menus/Links'
import { notifyError, notifyMessage } from '@/services/notify'
import { propertyStore } from '@/services/Properties/store'

export const defaultDateFormat = 'MM/dd/yyyy'

import { DEFAULT_FORM_VALUES, data } from './parts/data'

export default {
  components: {
    PageContent,
    SlideTransition,
    LinksMenu
  },
  data,

  created() {
    if (this.isDebug == true) console.debug('authUser=' + JSON.stringify(this.authUser))

    if (this.authUser && this.authUser != undefined) {
      this.ownerID = this.authUser.ownerID
    }

    this.getRefreshedVehicles()
  },

  computed: {
    ...mapState({
      ...mapGetters('unit', ['unitImageBase64']),
      hoaId: state => state.user.homeownerSelectedUnit.hoaId,
      unitId: state => state.user.homeownerSelectedUnit.unitId,
      ...mapGetters('user', ['authUser', 'retrieveUser']),
      breakpoint: state => state.win.breakpoint
    })
  },

  async mounted() {
    this.loading = true

    if (this.authUser && this.authUser != undefined) {
      this.ownerID = this.authUser.ownerID
    }

    this.reload()

    this.loading = false
  },

  watch: {
    unitId() {
      this.reload()
    },
    authUser() {}
  },

  methods: {
    ...mapActions('user', ['selectHoaId', 'userRetrieve']),

    confirmVehicleDelete(id, vehicle) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Vehicle',
        message: `Are you sure you want to <b>delete</b> this vehicle, '${vehicle}'?`,
        confirmText: 'Delete Vehicle',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteVehicle(id)
      })
    },
    confirmDeleteVehicle(id, year, make, model) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Vehicle',
        message: `Are you sure you want to <b>delete</b> this ${year} ${make} ${model}?`,
        confirmText: 'Delete Vehicle',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteVehicle(id)
      })
    },
    confirmVehicleUpdate(selection) {
      this.$buefy.dialog.confirm({
        title: 'Updating Vehicle',
        message: `Are you sure you want to <b>update</b> this vehicle, '${selection.value}'?`,
        confirmText: 'Update Vehicle',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.updateVehicle(selection),
        onCancel: () => this.getRefreshedVehicles()
      })

      if (this.isDebug == true) console.debug('selected row value=' + this.selectedRow.vehicle)
    },
    confirmUpdate() {
      this.$buefy.dialog.confirm({
        title: 'Updating Profile',
        message:
          'Are you sure you want to <b>update</b> this record? Changing your email address will require that you use your new email address the next time that you login.',
        confirmText: 'Update User Profile',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.onProfileFormSubmit()
      })
    },

    async loadUnitImage() {
      const payload = {
        unitID: this.unitId,
        asBase64: true
      }

      await propertyStore
        .dispatch('getDefaultUnitImage', {
          payload
        })
        .then(result => {
          if (result) {
            this.unitImageBase64 = result.toString()
            console.debug('this.unitImageBase64=' + this.unitImageBase64)
          }
        })
    },

    async reload() {
      this.loading = true

      if (this.isDebug == true) console.debug('state unitId in property settings=' + this.unitId)

      this.$store.dispatch('unit/getDefaultUnitImage', {
        unitID: this.unitId,
        asBase64: true
      })

      if (this.unitImageBase64) {
        if (this.isDebug == true) console.debug('this.unitImageBase64=...' + this.unitImageBase64)
      } else {
        if (this.isDebug == true) console.debug('no default image found...')
      }

      //Fixes a latent image loading bug
      await this.loadUnitImage()

      this.refreshUserProfile()

      //Load unit details for selected unit
      this.getUnitById()

      this.getRefreshedVehicles()

      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }

      this.loading = false
    },

    async getUnitById() {
      if (this.isDebug == true) console.debug('getUnitById=' + this.unitId)
      if (this.unitId && this.unitId != undefined && this.unitId > 0) {
        try {
          const params = {
            unitID: this.unitId
          }
          const path = `/Roster/Unit/${this.unitId}`
          const returned = await kms.get(path, {
            params
          })

          if (this.isDebug == true) console.debug('getUnitById=' + JSON.stringify(returned))

          if (returned) {
            this.unitDetail = returned

            //Primary Owner
            if (
              returned.primaryOwner &&
              returned.primaryOwner != undefined &&
              returned.primaryOwner.ownerContacts &&
              returned.primaryOwner.ownerContacts != undefined
            ) {
              const contacts = returned.primaryOwner.ownerContacts
              if (this.isDebug == true) console.debug('contacts=' + JSON.stringify(contacts))

              this.primaryOwner = contacts
                .map(a => ({
                  value: a.ownerID,
                  label: `${a.firstName} ${a.lastName}`
                }))
                .filter(f => f.value == returned.primaryOwnerID)[0]

              if (this.isDebug == true)
                console.debug('primaryOwner=' + JSON.stringify(this.primaryOwner))
            }
          }
        } catch (e) {
          if (this.isDebug == true) console.debug('issue loading getUnitById=' + e)
        }
      }
    },

    async getRefreshedVehicles() {
      if (this.ownerID && this.ownerID != undefined) {
        const params = {
          ownerID: this.ownerID
        }

        if (this.isDebug == true) console.debug('params=' + JSON.stringify(params))

        const path = `/Roster/Vehicle/List`
        const { results: returned } = await kms.get(path, {
          params
        })

        this.vehicleList = returned

        if (this.isDebug == true) console.debug('vehicleList=' + JSON.stringify(this.vehicleList))
      }
    },

    async deleteVehicle(id) {
      if (id <= 0) {
        notifyError('Unable to delete this vehicle.')
        return
      }

      try {
        this.loading = true
        const data = { vehicleID: id, forceDelete: false }
        const path = `/Roster/Vehicle/${id}?forceDelete=false`
        const results = await kms.delete(path, data)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results.recordID >= 0) {
          this.formComplete = true

          this.getRefreshedVehicles()

          notifyMessage(`The selected vehicle was successfully deleted.`)
        } else {
          notifyError('There was a problem deleting this vehicle.')
        }
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    },

    async updateVehicle(selection) {
      if (this.isDebug == true) console.debug(JSON.stringify(selection))

      if (!selection || selection.vehicleID <= 0) {
        notifyError('Unable to update this vehicle.')
        return
      }

      try {
        this.loading = true

        const path = `/Roster/Vehicle`
        const results = await kms.put(path, this.getVehiclePayload())

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results.vehicleID >= 0) {
          this.formComplete = true
          this.resetForm()
          this.getRefreshedVehicles()

          notifyMessage(`The selected vehicle was successfully updated.`)
        } else {
          notifyError('There was a problem updating this vehicle.')
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    async refreshUserProfile() {
      try {
        const refreshedUser = await kms.get('/User')

        if (this.isDebug == true) console.debug('refreshedUser=' + JSON.stringify(refreshedUser))

        if (refreshedUser && refreshedUser != undefined) {
          this.profile = refreshedUser
          this.formData.fullName = this.profile.name
          this.formData.emailAddress = this.profile.email
          this.formData.phone = this.profile.phoneNumber
          if (this.isDebug == true) console.debug('this.profile=' + JSON.stringify(this.profile))
        } else {
          if (this.isDebug == true) console.debug('else this.profile')
        }
      } catch (e) {
        notifyError(e)
      }
    },

    setChangePasswordModal() {
      this.toggle = true
    },

    setVehicleModal() {
      this.toggleVehicle = true
    },

    getProfileFormPayload() {
      const payload = {
        name: this.formData.fullName,
        email: this.formData.emailAddress,
        phoneNumber: this.formData.phone
      }

      return payload
    },
    async leaveHoa(leaveHoaID, hoaName) {
      if (!this.authUser || this.authUser == undefined || !leaveHoaID || leaveHoaID == undefined) {
        notifyError('There was a problem leaving this Hoa.')
        return
      }

      const leavePayload = {
        accountManagingUserID: this.authUser.accountManagingUserID,
        accountID: this.authUser.accountID,
        hoaID: leaveHoaID
      }

      try {
        const path = `/User/LeaveHoa?accountManagingUserID=${leavePayload.accountManagingUserID}&accountID=${leavePayload.accountID}&hoaID=${leavePayload.hoaID}`
        const results = await kms.post(path, leavePayload)

        if (this.isDebug == true) console.debug(JSON.stringify(results))

        if (results && results != undefined) {
          if (results == true) {
            notifyMessage(`Successfully left ${hoaName}`)
            this.reload()
          } else {
            notifyError(`There was a problem leaving this Hoa.`)
          }
        } else {
          notifyError('There was a problem leaing this Hoa.')
        }
        this.loading = false
      } catch (e) {
        notifyError(e)
        this.loading = false
      }
    },
    async onProfileFormSubmit() {
      try {
        if (!this.formData || this.formData == undefined) {
          notifyError('There was a problem saving your user profile.')
          return
        }

        this.loading = true
        console.debug('payload formData= ' + JSON.stringify(this.formData))
        const returnPayload = this.getProfileFormPayload()
        console.debug('payload= ' + JSON.stringify(returnPayload))

        try {
          const path = `/User/SaveIdentityUserProfile`
          const results = await kms.post(path, returnPayload)

          if (this.isDebug == true) console.debug(JSON.stringify(results))

          if (results && results != undefined) {
            if (results == true) {
              notifyMessage(`Your user profile has been updated`)
              this.reload()
            } else {
              notifyError(`There was a problem updating your user profile`)
            }
          } else {
            notifyError('There was a problem updating the detailed description.')
          }
          this.loading = false
        } catch (e) {
          notifyError(e)
        }
        this.loading = false
      } catch (e) {
        notifyError(e)
      }

      this.loading = false
    }
  },
  i18n: {
    messages: {
      en: { userSettingsDetail: { title: 'User Settings Detail' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em 0.5em 0.5em 0.5em;
  }
}

.details-menu {
  a {
    color: #838383;
    display: inline-block;
    padding: 0.5em 0;

    &:hover,
    &:active {
      color: inherit;
    }
    &.router-link-active {
      color: #ff7300; // #381;
      &::after {
        content: '→';
      }
    }
  }
}
</style>
