var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',[_c('div',{staticClass:"box is-12",attrs:{"tests-id":"propertySettings-page"}},[_c('div',{staticClass:"tile is-ancestor is-12"},[_c('div',{staticClass:"tile is-vertical is-12"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"p-5 level-left level-item title"},[_vm._v("Property Settings")]),_c('div',{staticClass:"pr-5 level-right"},[(_vm.unitDetail && _vm.unitDetail != undefined)?_c('div',{staticClass:"level-item"},[_c('label',{staticClass:"pr-2 h6 is-inline"},[_vm._v("Property Id:")]),_vm._v(" "+_vm._s(("" + (_vm.unitDetail.lotNumber || '')))+" ")]):_vm._e()])]),(_vm.unitImageBase64)?_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-center level-item"},[_c('figure',{staticClass:"image is-8x4 details-img"},[_c('img',{style:({ maxWidth: '800px' }),attrs:{"src":_vm.unitImageBase64}})])])]):_vm._e(),_c('div',{staticClass:"level"},[(
              _vm.unitDetail &&
                _vm.unitDetail != undefined &&
                _vm.unitDetail.address &&
                _vm.unitDetail.address != undefined
            )?_c('div',{staticClass:"pl-5 title is-5 is-vcentered level-left level-item"},[_vm._v(" "+_vm._s(("" + (_vm.unitDetail.address.addressOne || '')))+" ")]):_vm._e(),_c('div',{staticClass:"level-right"},[(_vm.primaryOwner && _vm.primaryOwner != undefined)?_c('div',{staticClass:"pr-5 level-item"},[_c('label',{staticClass:"pr-2 h6 is-inline"},[_vm._v("Deeded Name:")]),_vm._v(" "+_vm._s(("" + (_vm.primaryOwner.label || '')))+" ")]):_vm._e()])]),_c('div',{staticClass:"level"},[(
              _vm.unitDetail &&
                _vm.unitDetail != undefined &&
                _vm.unitDetail.address &&
                _vm.unitDetail.address != undefined
            )?_c('div',{staticClass:"pl-5 subtitle is-6 is-vcentered level-left level-item"},[_vm._v(" "+_vm._s(((_vm.unitDetail.address.city || '') + ", " + (_vm.unitDetail.address.state || '') + " " + (_vm.unitDetail .address.postalCode || '')))+" ")]):_vm._e(),_c('div',{staticClass:"level-right"},[(
                _vm.unitDetail &&
                  _vm.unitDetail != undefined &&
                  _vm.unitDetail.primaryOwner &&
                  _vm.unitDetail.primaryOwner != undefined
              )?_c('div',{staticClass:"pr-5 level-item"},[_c('label',{staticClass:"pr-2 h6 is-inline"},[_vm._v("Settlement Date:")]),_vm._v(" "+_vm._s(("" + (_vm.formatDateFn(_vm.unitDetail.primaryOwner.settlementDate))))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"card",style:({
      minHeight: '800px'
    }),attrs:{"id":"tabs"}},[_c('div',{staticClass:"card-content"},[_c('LinksMenu',{style:({
          paddingLeft: '1px',
          overflowX: 'auto',
          width: '100%'
        }),attrs:{"aria-label":"account settings tabs","tabs":_vm.tabs,"prefix":"property.","styles":{ ul: { width: '150px' } },"params":{
          id: ("" + (_vm.unitDetail && _vm.unitDetail.primaryOwnerID && _vm.unitDetail.primaryOwnerID !== undefined
              ? _vm.unitDetail.primaryOwnerID
              : 0))
        }}},[_c('section',{style:({ overflowX: 'auto', flexGrow: 10 })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('keep-alive',[_vm._v(" "+_vm._s(_vm.transitionName)+" "),_c('router-view',{key:_vm.$route.name})],1)],1)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }