/*
  import { propertyStore } from '@/services/Properties/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
const isDebug = false

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await propertyStore.dispatch ('getDefaultUnitImage', {
      payload
    });
  */

  async getDefaultUnitImage({}, { payload = {} }) {
    if (isDebug == true) console.debug('in getDefaultUnitImage...')
    try {
      if (payload && payload != undefined && payload.unitID !== undefined && payload.unitID) {
        const params = {
          unitID: payload.unitID,
          asBase64: payload.asBase64,
          asRawBase64: payload.asRawBase64
        }

        const defaultImage = await kms.get(`/Roster/Unit/RetrieveUnitImage`, {
          params
        })

        if (isDebug == true) {
          console.debug('getDefaultUnitImage=' + JSON.stringify(defaultImage))
        }

        return {
          result: defaultImage
        }
      }
    } catch (e) {
      console.debug(e)
    }

    return {
      result: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const propertyStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
